// React Required
import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
import Business from "./home/Business";

// Element Layout
import Service from "./elements/Service";
import ServiceDetails_1 from "./elements/ServiceDetails_1";
import ServiceDetails_2 from "./elements/ServiceDetails_2";
import ServiceDetails_3 from "./elements/ServiceDetails_3";
import ServiceDetails_4 from "./elements/ServiceDetails_4";
import ServiceDetails_5 from "./elements/ServiceDetails_5";
import ServiceDetails_6 from "./elements/ServiceDetails_6";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";

// Blocks Layout

import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Business} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/Business`}
              component={Business}
            />
            {/* Element Layot */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service`}
              component={Service}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/details-service-1`}
              component={ServiceDetails_1}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/details-service-2`}
              component={ServiceDetails_2}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/details-service-3`}
              component={ServiceDetails_3}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/details-service-4`}
              component={ServiceDetails_4}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/details-service-5`}
              component={ServiceDetails_5}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/details-service-6`}
              component={ServiceDetails_6}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              component={Contact}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/about`}
              component={About}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/portfolio-details`}
              component={PortfolioDetails}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog`}
              component={Blog}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-details`}
              component={BlogDetails}
            />

            {/* Blocks Elements  */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/team`}
              component={Team}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/counters`}
              component={Counters}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/testimonial`}
              component={Testimonial}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/portfolio`}
              component={Portfolio}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/video-popup`}
              component={VideoPopup}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/gallery`}
              component={Gallery}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/clint-logo`}
              component={Brand}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/progressbar`}
              component={ProgressBar}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact-form`}
              component={ContactForm}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/google-map`}
              component={GoogleMap}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/columns`}
              component={Columns}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/solutions`}
              component={PricingTable}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/404`}
              component={error404}
            />
            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
serviceWorker.register();
